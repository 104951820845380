import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BidStatus,
  EOIStatus,
  PaymentStatus,
  PaymentStorageVariable,
  Role,
} from "./EnumConstants";
import {
  IBidDocumentsResponse,
  IVentureDocuments,
} from "../Components/EditBids/EditBids";
import { BidDocumentsTab } from "../Components/Context/BidContext/BidContext";
import * as yup from "yup";
import { BidDocumentInitialValues } from "../Components/FormikWrapper/BidFornikWrapper";
import { ResponseTenderCard } from "../Components/TenderCard/TenderCardGroup";
import { DetailBid } from "../Components/BidDetails/BidDetails";
import { IJointVenture } from "../Components/JointVenture/JointVenture";
import { Config } from "./config";
import * as CryptoJS from "crypto-js";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { OrganizationCategory } from "../Components/utils/OrganizationCategory";

export const paymentPromiseHandler = (
  promiseType: "Success" | "Failed",
  redirectPath: string,
  navigate: NavigateFunction,
  storageVariableName: PaymentStorageVariable,
  response?: any
) => {
  if (promiseType === "Success") {
    toast.success(
      response ? response.data.status.status_message : "Payment Completed"
    );
  }
  if (promiseType === "Failed") {
    toast.error("Payment Verification Error");
  }
  setTimeout(() => {
    navigate(redirectPath as string);
    window.localStorage.removeItem(storageVariableName);
  }, 1000);
};

export const fixedValue = (value = 0, toFix = 2) => {
  let check = 0;
  if (!isNaN(value)) {
    check = Number.isInteger(value) ? value : Number(value.toFixed(toFix));
  }
  return Number(check);
};

export const isBidder = (role: string) => {
  return role.toLocaleLowerCase() === Role.Bidder.toLocaleLowerCase();
};

export const isPublisher = (role: string) => {
  return role.toLocaleLowerCase() === Role.Publisher.toLocaleLowerCase();
};

export const getDateTime = (date: string | number | Date) => {
  const newDate = new Date(date);
  return newDate;
};

export const getBidOpenDateTime = (date: string | number | Date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 2); // Add 2 hours
  return newDate;
};

export const deepClone = (valueToClone: any) =>
  JSON.parse(JSON.stringify(valueToClone));

export const filledDocuments = (
  needDocument: string,
  filledDocument: IBidDocumentsResponse
) => {
  const obj: any = {};
  if (filledDocument !== undefined) {
    let splittedNeededDocument = needDocument.split(",");
    //tax clearance would always be there
    if (splittedNeededDocument.length <= 0) return obj;
    splittedNeededDocument.forEach((documentName: string) => {
      obj[documentName] =
        filledDocument[documentName as keyof IBidDocumentsResponse];
    });
  }
  return obj;
};

export const generateBidDocumentValidationSchema = (
  document: BidDocumentsTab
) => {
  const schemaObject = {};
  Object.keys(document).forEach((key) => {
    //@ts-ignore
    schemaObject[key] = yup.string().required("Required Document");
  });
  return yup.object().shape(schemaObject);
};

export function getValueFromNestedObject(obj: Object, path: string) {
  const keys = path.split("."); // Split the path string at dot ('.')
  let value = obj;
  for (const key of keys) {
    //@ts-ignore
    value = value[key];
    if (value === undefined) {
      // Property not found, return some default value or handle the error
      return null;
    }
  }

  return value;
}

export const convertToFormData = <T>(normalData: T): FormData => {
  const formdata = new FormData();
  Object.entries(normalData as Object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      formdata.append(key, JSON.stringify(value));
    } else {
      formdata.append(key, value);
    }
  });
  return formdata;
};

export const isNoticeCategoryEOI = (noticeCategory: string) =>
  noticeCategory.toLowerCase().includes("eoi");

export const isNoticeCategoryStandingList = (noticeCategory: string) => {
  return noticeCategory.toLowerCase()?.includes("enlistment");
};

export const isNoticeCategoryResult = (noticeCategory: string) =>
  noticeCategory.toLowerCase().includes("result");

export function concatWithComma(str1: string, str2: string) {
  return [str1, str2].filter((s) => s.trim() !== "").join(",");
}

export const ifAnyOfJointVentureFieldIsFilled = (
  ventureDocuments: IVentureDocuments
) => {
  let allValueFilled = false;
  if (!Boolean(ventureDocuments)) return false;
  Object.values(ventureDocuments).forEach((doc) => {
    if (Boolean(doc)) {
      allValueFilled = true;
    }
  });
  return allValueFilled;
};

export const CheckTechnicalPassStatus = (
  technical_pass: boolean | null
): EOIStatus => {
  let status: EOIStatus | string = "";
  switch (technical_pass) {
    case null:
      status = EOIStatus.Submitted;
      break;
    case true:
      status = EOIStatus.Pass;
      break;
    case false:
      status = EOIStatus.Fail;
      break;
    default:
      status = "";
  }
  return status as EOIStatus;
};

export const FirstValueFromSplit = (str: string) => {
  return str.split(".")[0];
};

export const TabKeys = (keys: keyof BidDocumentInitialValues) => {
  switch (keys) {
    case "bidFee":
      return "Bid Fee";
    case "boq_details":
      return "BOQ";
    case "documents":
      return "Documents";
    case "jointVenture":
      return "Joint venture";
    default:
      return "";
  }
};

//expect errorObj as example {'jointVenture.tax_clearence':'Required'} from which we have to etract jointventure only
export const concateToasterError = (errorObj: BidDocumentsTab) => {
  if (!Boolean(errorObj)) return;
  if (Object.entries(errorObj).length <= 0) return;
  let errormessage = " is pending";
  let completerError: string[] = [];
  Object.entries(errorObj).forEach(([key, val]) => {
    completerError.push(
      //FirstValueFromSplit from this function we get jointVenture which is puseh to completeError array
      TabKeys(FirstValueFromSplit(key) as keyof BidDocumentInitialValues)
    );
  });
  //@ts-ignore
  const uniqueArray = [...new Set(completerError)];
  return uniqueArray.join(",") + errormessage;
};

export const bidderButtonShow = (
  tenderDetails: ResponseTenderCard,
  bidStatus: string,
  isPublisherProfile?: boolean
) => {
  let showPay = false,
    showEdit = false,
    showDelete = false,
    showView = true,
    showWithdrawn = false,
    showResubmit = false,
    showApplyBid = false,
    showViewDocument = false;

  let notice_category = tenderDetails?.notice_category.toLowerCase();
  let hasAwards = tenderDetails?.award_result.length > 0;
  let isLive = Number(tenderDetails?.remaining_days) > 0;

  let openingDays = tenderDetails?.opening_date
    ? getDateDifferenceInDays(
        new Date().toISOString(),
        tenderDetails?.opening_date
      )
    : 0;

  let hasOpeningDate = openingDays > 0;

  let hasPaid =
    tenderDetails?.extra_info?.e_payment.toLowerCase() ===
    PaymentStatus.Submitted.toLowerCase();
  if (bidStatus === BidStatus.IN_PROGRESS) {
    // buttons are pay, edit, delete, view
    if (!isPublisherProfile && !hasAwards && isLive) {
      showEdit = true;
      showPay =
        !tenderDetails.publisher_bid_fee &&
        !notice_category.includes("eoi") &&
        !hasPaid;
      showDelete = !hasPaid;
      showView = false;
    }
    if (!isLive) {
      showDelete = true;
    }
  } else if (bidStatus === BidStatus.EOI_STATUS) {
    // buttons are edit as process , view, withdrawns
    if (!hasAwards && (isLive || hasOpeningDate)) {
      showWithdrawn =
        notice_category.includes("eoi") &&
        !hasPaid &&
        tenderDetails.technical_pass === null;
      showView = tenderDetails.technical_pass !== null;
      showEdit = tenderDetails.technical_pass === true;
    }
  } else if (bidStatus === BidStatus.SUBMITTED) {
    // buttons are view , withdrawn
    showViewDocument = !isPublisherProfile;
    showApplyBid = isPublisherProfile || false;
    showWithdrawn = !hasAwards && isLive && !isPublisherProfile;
  } else if (bidStatus === BidStatus.WITH_DRAWN_OR_AWARDED) {
    // buttons are resubmit , edit
    if (!hasAwards && isLive) {
      showResubmit = true;
      showView = false;
      showEdit = true;
    }
    if(!isLive && !isPublisherProfile) {
      showDelete = true;
    }
  } else if (bidStatus === BidStatus.ENLISTMENT) {
    if (!hasAwards && isLive) {
      showApplyBid = true;
    }
  }
  return {
    showPay,
    showEdit,
    showDelete,
    showView,
    showWithdrawn,
    showResubmit,
    showApplyBid,
    showViewDocument,
  };
};

export const publisherButtonShow = (
  tenderDetails: ResponseTenderCard,
  tenderStatus: string
) => {
  let showPublisherPay = false,
    showPublisherEdit = false,
    showPublisherDelete = false,
    showPublisherView = true,
    showRequestQuotation = false;
  // let notice_category = tenderDetails?.notice_category.toLowerCase();
  let hasAwards = tenderDetails?.award_result.length > 0;
  let amendment = tenderDetails.amendment;

  if (tenderStatus === BidStatus.IN_PROGRESS) {
    if (!hasAwards) {
      showPublisherPay = !tenderDetails.deposit_paid;
      showPublisherEdit = true;
      showPublisherDelete = true;
      showPublisherView = false;
    }
  } else if (tenderStatus === BidStatus.ENLISTMENT) {
    if (!hasAwards && Number(tenderDetails?.remaining_days) > 5 && !amendment) {
      showPublisherEdit = true;
    }
    if (tenderDetails?.opening_date) {
      showRequestQuotation = true;
    }
  } else if (
    tenderStatus === BidStatus.SUBMITTED ||
    tenderStatus === BidStatus.E_NOTICE
  ) {
    if (!hasAwards && Number(tenderDetails?.remaining_days) > 5) {
      showPublisherEdit = !amendment;
    }
  }
  return {
    showPublisherPay,
    showPublisherEdit,
    showPublisherDelete,
    showPublisherView,
    showRequestQuotation,
  };
};

export const conditionalRenderBidTabs = (
  noticeCategory: string,
  openingDate: string,
  isMain: boolean
) => {
  let renderOptions = {
    Jointventure: true,
    Documents: true,
    Fee: true,
    BOQ: true,
  };
  if (isNoticeCategoryEOI(noticeCategory)) {
    if (Boolean(openingDate)) {
      renderOptions.Jointventure = false;
      renderOptions.Documents = false;
    } else {
      renderOptions.Fee = false;
      renderOptions.BOQ = false;
    }
  } else if (isNoticeCategoryStandingList(noticeCategory)) {
    renderOptions.Jointventure = false;
    if (!isMain || Boolean(openingDate)) {
      renderOptions.Documents = false;
      renderOptions.Fee = false;
    } else {
      renderOptions.BOQ = false;
    }
  }
  return renderOptions;
};

export const modalStatesSetAllFalseExceptGiven = <T>(
  obj: T,
  property: keyof T
) => {
  const tempObj = {};
  //@ts-ignore
  Object.keys(obj).forEach((val: keyof typeof tempObj) => {
    //@ts-ignore
    tempObj[val] = false;
  });
  //@ts-ignore
  tempObj[property] = true;
  return tempObj;
};

export const encryptData = (data: string) => {
  const key = CryptoJS.enc.Utf8.parse(Config.CRYPTO_SECRET_KEY);
  const iv = CryptoJS.enc.Utf8.parse(Config.IV_KEY);
  let encrypted = CryptoJS.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  encrypted = encrypted.toString();
  return encrypted;
};

export const decryptData = (data: string): string => {
  const iv = CryptoJS.enc.Utf8.parse(Config.IV_KEY);
  const key = CryptoJS.enc.Utf8.parse(Config.CRYPTO_SECRET_KEY);
  let decrypted = CryptoJS.AES.decrypt(data, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export const removeLastHeaderAndItem = (
  table: HTMLTableElement
): HTMLTableElement | null => {
  // Check if the table exists
  if (table) {
    // Get the table rows
    const rows = table.rows;

    // Check if there are any rows in the table
    if (rows.length > 0) {
      // Get the number of columns in the table
      const numColumns = rows[0].cells.length;

      // Remove the last header (first row)
      const lastHeaderRow = rows[0];
      lastHeaderRow.deleteCell(numColumns - 1);

      // Loop through the rows starting from the second row (skipping the header)
      for (let i = 1; i < rows.length; i++) {
        // Remove the last item in each row
        const lastItemCell = rows[i].cells[numColumns - 1];
        rows[i].deleteCell(numColumns - 1);
      }

      return table; // Return the modified table
    }
  }

  return null; // Return null if the table doesn't exist or has no rows
};

export const printTable = (tableId: string, tenderDetails: DetailBid) => {
  // Create a new window for printing
  const printWindow = window.open("", "", "width=800,height=600");

  // Define the HTML content for the table
  const tableHtml = document.getElementById(tableId) as HTMLTableElement;
  const clonedTable = tableHtml.cloneNode(true) as HTMLTableElement;
  const actionColumnRemovedTable = removeLastHeaderAndItem(clonedTable);
  const htmlWithCss = `
  <html>
    <head>
      <style>
      /* Define your CSS styling for the table here */
      table {
        font-size:14px;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dee2e6; /* Add the border style here */
      }
      th, td {
        border: 1px solid #dee2e6; /* Add the border style here */
        padding: 8px;
        text-align: left;
      }
      th {
        background: linear-gradient(180deg, #0052a7 0.79%, #0065c3 100%);
        color: #fff;
      }
      .above-header {
        display: flex;
        justify-content: space-between;
        padding: 10px;
      }
      .grid-container {
        display: grid;
        grid-template-columns: auto auto ;
        gap: 10px;
        background-color: #f2f7fa;
        padding: 10px;
        font-size:12px;
        margin-bottom:10px;
      }
      .grid-container label{
        color: #666666;
      }
      </style>
    </head>
    <body>
    <center><img
    width="300"
    src="https://bolpatranepal.s3.ap-south-1.amazonaws.com/bolpatra_logo_2023-10.png"
    alt="letter-head"
  />
  </center>
    <div class='grid-container' >

          <div class='grid-item'><label>Notice No:</label><span> ${
            tenderDetails.tender_number
          }</span></div>
          <div class='grid-item'  ><label>Project Title:</label><span>${
            tenderDetails.title
          }</span> </div>
    
          <div class='grid-item'  > <label>Entity Name:</label><span> ${
            tenderDetails.public_entity_name
          }</span></div>
          <div class='grid-item'  ><label>Procurement Type:</label><span>${tenderDetails.procurement_type
            .map((each) => each.label)
            .join(",")}</span>  </div>
          <div class='grid-item'  > <label>Published Date:</label><span> ${
            tenderDetails.pubilshing_date
          }</span> </div>
          <div class='grid-item'  ><label>Project Type:</label><span>${tenderDetails.project_category
            .map((each) => each.label)
            .join(",")}</span> </div>
              
          <div class='grid-item'  ><label>Last Submission Date:</label><span> ${
            tenderDetails.submission_date.slice(0, 10) +
            " | " +
            new Date(tenderDetails.submission_date).toLocaleTimeString()
          }</span> </div>
          <div class='grid-item'  ><label>Notice Category:</label><span>${
            tenderDetails.notice_category
          }</span> </div>
          </div>
    <div>
      ${(actionColumnRemovedTable as HTMLTableElement).outerHTML}
    </div>
    </body>
  </html>
`;

  // Write the HTML content to the new window and print it
  (printWindow as Window).document.open();
  (printWindow as Window).document.write(htmlWithCss);
  (printWindow as Window).document.close();
  (printWindow as Window).print();

  // Close the new window after printing
  (printWindow as Window).close();
};

export const ifAnyOfKeyisFilled = (documents: IJointVenture) => {
  let flag = false;
  Object.values(documents).forEach((val) => {
    if (val !== "") {
      flag = true;
    }
  });
  return flag;
};

export const getDateDifferenceInDays = (
  date1: string,
  date2: string
): number => {
  const newDate1 = new Date(date1);
  const newDate2 = new Date(date2);
  const timeDifference = newDate2.getTime() - newDate1.getTime();
  const daysDifference = timeDifference / (1000 * 3600 * 24);
  return Math.round(daysDifference);
};

export const dowloadXlsxFile = (columns: any, data: any, fileName: any) => {
  const xlsxData = [
    columns.map((column: any) => column.Header),
    ...data.map((row: any) =>
      columns.map((col: any) => row[col.accessor] || "Not Available")
    ),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(xlsxData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([buffer], { type: "application/octet-stream" });
  saveAs(blob, `${fileName || "bidder-list"}.xlsx`);
};

export const findOrganizationLabelByValue = (value: string) => {
  const category = OrganizationCategory.find((item) => item.value === value);
  return category ? category.label : "N/A";
};

export const shouldShowEditCompanyPopup = (
  isAuthenticate: boolean,
  eligible: boolean,
  email: string
) => {
  const companyPopupData = localStorage.getItem("loginDataArray");
  let showCompanyPopup = false;

  if (companyPopupData) {
    const parsedData = JSON.parse(companyPopupData);

    const foundData = parsedData.find(
      (data: { firstLoginEmail: string }) => data.firstLoginEmail === email
    );

    showCompanyPopup =
      foundData?.showCompanyPopup === "true" &&
      isAuthenticate &&
      !eligible;
  }
  return { showCompanyPopup };
};
